const showAnimation = () => {
    let animScale = document.querySelectorAll('.js--anim-scale-on');
    
    if(animScale.length == 0) return;

    const options = {
        threshold: [0.4]
    };

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;

            if(isIntersecting) {
                target.classList.add('anim-scale-on--active');
            } else {
                target.classList.remove('anim-scale-on--active');
            }
        });
    };

    const observer = new IntersectionObserver(callback, options);

    animScale.forEach(elem => {
        elem.classList.add('anim-scale-on');
        observer.observe(elem);
    });
};

const showAnimationDown = () => {
    let animScale = document.querySelectorAll('.js--anim-show-down');
  

    if(animScale.length == 0) return;
    
    const options = {
        threshold: [0.1]
    };
    console.log(animScale)
    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;

            if(isIntersecting) {
                target.classList.add('anim-show-down--active');
            } else {
                target.classList.remove('anim-show-down--active');
            }
        });
    };

    const observer = new IntersectionObserver(callback, options);

    animScale.forEach(elem => {
       // elem.classList.add('anim-scale-on');
        observer.observe(elem);
    });
};

export {
    showAnimation,
    showAnimationDown
}
