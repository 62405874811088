import Swiper, { Navigation, Mousewheel, Pagination } from 'swiper';

import lazyLoadPucture from './components/lazyLoadPucture.js'
import lazyLoadBackground from './components/lazyLoadBackground.js'

import changeBurger from '../components/elements/burger/burger.js'
import showMenu from '../components/elements/menu/menu.js'
import * as header from '../components/elements/header/header.js'
import rememberCookie from '../components/elements/cookie/cookie.js'
import { showModal } from '../components/elements/overlay/overlay.js'
import { sendForm } from '../components/elements/form/form.js'
import { openSelect, filter } from '../components/elements/filter/filter.js'

import { showAnimation, showAnimationDown } from '../components/animation/animation.js'


window.addEventListener('load', () => {

  
  let card_slider = new Swiper(".card-slider", {
    modules: [Navigation],
    navigation: {
      nextEl: ".card-slider .swiper-button-next",
      prevEl: ".card-slider .swiper-button-prev",
    },
  });
    lazyLoadPucture();
    lazyLoadBackground();
    showModal();

    changeBurger();
    showMenu();
    header.fixedMenu();
    header.openSubMenu();
    rememberCookie();

    showAnimation();
    showAnimationDown();
    sendForm();
    openSelect();
    filter();
});