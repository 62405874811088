// import _clickOutside from "../../js/_clickOutside.js";
const openSelect = () => {
    const select = document.querySelectorAll('.js--app-select');

    if(select.length === 0) return;

    select.forEach(elem => {

        const open = elem.querySelector('.js--app-select-title');
        const content = elem.querySelector('.js--app-select-content');
        const list = content.querySelectorAll('label');
        const titleDefault = open.innerHTML;

        open.addEventListener('click', () => {
            if (!content.classList.contains('select__checkboxShow')) {
                content.classList.add('select__checkboxShow');
                open.classList.add('active');
            } else {
                content.classList.remove('select__checkboxShow');
                open.classList.remove('active');
            }
        });

        list.forEach(item => {
            item.addEventListener('click', () => {
                content.classList.remove('select__checkboxShow');
                open.classList.remove('active');
            });
        });
        elem.querySelectorAll('input').forEach(item => {
            item.addEventListener('click', () => {
                if (item.checked) {
                    open.innerHTML = item.value;
                }
                if (open.innerHTML == ' ' || open.innerHTML == '') {
                    open.innerHTML = titleDefault;
                }
            })
        });

        // document.addEventListener('click', function (event) {
        //     let result = _clickOutside('open', content, 'js--app-select');
        //     if(result) {
        //         content.classList.remove('select__checkboxShow');
        //         open.classList.remove('active');
        //     }
        // });
    });
}
const sortAreaDown = (wrap, nodeList) => {
    let itemsArray = [];

    for (let i = 0; i < nodeList.length; i++) {    
      itemsArray.push(wrap.removeChild(nodeList[i]));
    }
    itemsArray.sort(function(nodeA, nodeB) {
        return nodeB.querySelector('.card').getAttribute('data-card-area') - nodeA.querySelector('.card').getAttribute('data-card-area');
      })
      .forEach(function(node) {
        wrap.appendChild(node)
    });     
}

const sortAreaUp = (wrap, nodeList) => {
    let itemsArray = [];

    for (let i = 0; i < nodeList.length; i++) {    
      itemsArray.push(wrap.removeChild(nodeList[i]));
    }
    itemsArray.sort(function(nodeA, nodeB) {
        return nodeA.querySelector('.card').getAttribute('data-card-area') - nodeB.querySelector('.card').getAttribute('data-card-area') ;
      })
      .forEach(function(node) {
        wrap.appendChild(node)
    });     
}

const sortPriceUp = (wrap, nodeList) => {
    let itemsArray = [];

    for (let i = 0; i < nodeList.length; i++) {    
      itemsArray.push(wrap.removeChild(nodeList[i]));
    }
    itemsArray.sort(function(nodeA, nodeB) {
        return nodeA.querySelector('.card').getAttribute('data-card-price-mes').replaceAll(' ', '').slice(0, -1) - nodeB.querySelector('.card').getAttribute('data-card-price-mes').replaceAll(' ', '').slice(0, -1);
      })
      .forEach(function(node) {
        wrap.appendChild(node)
    });     
}

const sortPriceDown = (wrap, nodeList) => {
    let itemsArray = [];

    for (let i = 0; i < nodeList.length; i++) {    
      itemsArray.push(wrap.removeChild(nodeList[i]));
    }
    itemsArray.sort(function(nodeA, nodeB) {
        return nodeB.querySelector('.card').getAttribute('data-card-price-mes').replaceAll(' ', '').slice(0, -1) - nodeA.querySelector('.card').getAttribute('data-card-price-mes').replaceAll(' ', '').slice(0, -1);
      })
      .forEach(function(node) {
        wrap.appendChild(node)
    });     
}

const filter = () => {
    const filter = document.querySelector('.js--filter');

    if(!filter) return;

    const tower = filter.querySelectorAll('.js--select-tower input');
    const sort = filter.querySelectorAll('.js--select-sort input');

    const cards = document.querySelectorAll('[data-filter-tower]');
    const cards_none = document.querySelector('.js--data-filter-none');
    const card_wrap = cards[0].parentElement;


    if(tower.length === 0 || sort.length === 0) return;
    let tower_value = "Все";
    sortPriceUp(card_wrap, cards)

    tower.forEach(elem => {
        elem.addEventListener('change', () => {
            let notCard = 0;
            if(elem.checked) {
                tower_value = elem.value;
                cards.forEach(el => {
                    if(tower_value == 'Все') {
                        el.classList.remove('d-none');
                        notCard = 1;
                    } else if(tower_value == el.getAttribute('data-filter-tower')) {
                        el.classList.remove('d-none');
                        notCard = 1;
                    } else {
                        el.classList.add('d-none');
                    }
                })
            } 
            if(!notCard) cards_none.classList.remove('d-none');
            else cards_none.classList.add('d-none');
        })
    })

    sort.forEach(elem => {
        elem.addEventListener('change', () => { 
            switch(elem.value) {
                case 'По цене ↑':  
                    sortPriceUp(card_wrap, cards)
                    break;
                case 'По цене ↓': 
                    sortPriceDown(card_wrap, cards)
                    break;
                case 'По метражу ↑':  
                    sortAreaUp(card_wrap, cards)
                    break;
                case 'По метражу ↓':  
                    sortAreaDown(card_wrap, cards)
                    break;
                default:
                    break;
              }
        })
    })

}

export { openSelect, filter }