const rememberCookie = () => {
    const overlay = document.querySelector('.js--cookie');

    if(!overlay) return;

    const btn = overlay.querySelector('.btn');

    if(!btn) return;

    if(getCookie('cookieShow') && getCookie('cookieShow') == 'true') {
        overlay.classList.add('d-none');
        overlay.classList.remove('d-flex');
    } else {
        overlay.classList.remove('d-none');
        overlay.classList.add('d-flex');
    }

    btn.addEventListener('click', () => {
        setCookie('cookieShow', true);
        overlay.classList.add('d-none');
        overlay.classList.remove('d-flex');
    })

    //document.cookie = "user=John";
    


    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(name, value, options = {}) {

        options = {
          path: '/',
          // при необходимости добавьте другие значения по умолчанию
          ...options
        };
      
        if (options.expires instanceof Date) {
          options.expires = options.expires.toUTCString();
        }
      
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
      
        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }
      
        document.cookie = updatedCookie;
      }
}

export default rememberCookie;