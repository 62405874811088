import Swiper, { Navigation } from 'swiper';
const findParent = (node, className) => {
    while (node) {
        if (node.classList.contains(className)) {
            return node;
        }
        else {
            node = node.parentElement;
        }
    }

    return null;
}

const find = (node) => {
    while (node) {
        if (node.hasAttribute('data-notshow-modal')) {
            return 0;
        } else {
            node = node.parentElement;
        }
    }
    return 1;
}

const containsModal = (e, overlay, btn = null, btn_delete = null, overlay_delete='', overlay_successful='', ) => {
    const margin = window.innerWidth - document.body.clientWidth;

    if(overlay.classList.contains('overlay-delete') && overlay_delete != '' && overlay_delete != '""') {
        const overlay_title = overlay.querySelector('.js--modal-delete-text span');
        if(overlay_title) overlay_title.innerHTML = overlay_delete;
    }

    if(overlay.classList.contains('overlay-successful') && overlay_successful != '' && overlay_successful != '""') {
        const overlay_title = overlay.querySelector('.js--modal-text');
        if(overlay_title) overlay_title.innerHTML = overlay_successful;
    }

    if(btn_delete) {
        btn_delete.addEventListener('click', () => {
            const file_wrap = findParent(btn, 'js--upload');
            if(file_wrap) file_wrap.classList.add('js--action-delete')
        })
    }

    document.documentElement.style.overflowY = 'hidden';
    document.documentElement.style.paddingRight = margin + 'px';

    overlay.style.zIndex = 999;
    if(btn ) btn.disabled = true;

    setTimeout(() => {
        overlay.classList.add('overlay-show')
        if(btn ) btn.disabled = false
    }, 100)
}

const showModal = (e) => {
    
    const btn = document.querySelectorAll('[data-show-modal]');

    let overlays = document.querySelectorAll(".overlay");

    const clickClose = (overlay) => {
        if (getComputedStyle(overlay.querySelector('.overlay-wrap')).marginRight != '0px') return
        overlay.classList.remove('overlay-show')
        document.documentElement.style.overflowY = 'auto';
        document.documentElement.style.paddingRight = '0';
        setTimeout(() => {
            overlay.style.zIndex = -1;
        }, 300)
    }

    overlays.forEach(overlay => {
        const closes = overlay.querySelectorAll('.js--overlay-close');

        if(closes.length != 0) {
            closes.forEach(close => {
                close.addEventListener('click', function(e) {

                    e.preventDefault()
                    clickClose(overlay)
                })
            })
        }
        overlay.addEventListener('click', function(e) {
            if (e.target != overlay) return
            clickClose(overlay)
        })
    })

    if(btn.length == 0) return;

    btn.forEach(elem => {
        let overlay = document.querySelector("." + elem.getAttribute('data-show-modal'));
        let overlay_delete = '';
        if(elem.hasAttribute('data-modal-delete')) overlay_delete = elem.getAttribute('data-modal-delete');
        let overlay_successful = 'Успешно удалено';
        if(elem.hasAttribute('data-modal-successful')) overlay_successful = elem.getAttribute('data-modal-successful');
        if(!overlay) return;

        const btn_delete = overlay.querySelector('.js--overlay-delete');
        const notshow = elem.querySelectorAll('[data-notshow-modal]');

        let overlay_area = '';
        let overlay_description = '';
        let overlay_floor = '';
        let overlay_price = '';
        let overlay_price_mes = '';
        let overlay_images = [];
        let overlay_title = '';
        let overlay_name = '';
        let title_feature_1 = '';
        let title_feature_2 = '';
        let title_feature_3 = '';
        let title_feature_4 = '';
        let title_feature_5 = '';

        if(elem.hasAttribute('data-card-description')) overlay_description = elem.getAttribute('data-card-description');
        if(elem.hasAttribute('data-card_images')) overlay_images = elem.getAttribute('data-card_images');
        if(elem.hasAttribute('data-card-area')) overlay_area = elem.getAttribute('data-card-area');
        if(elem.hasAttribute('data-card-floor')) overlay_floor = elem.getAttribute('data-card-floor');
        if(elem.hasAttribute('data-card-price-mes')) overlay_price_mes = elem.getAttribute('data-card-price-mes');
        if(elem.hasAttribute('data-card-price-year')) overlay_price = elem.getAttribute('data-card-price-year');
        if(elem.hasAttribute('data-card-title')) overlay_title = elem.getAttribute('data-card-title');
        if(elem.hasAttribute('data-card-name')) overlay_name = elem.getAttribute('data-card-name');
        if(elem.hasAttribute('data-card-feature-1')) title_feature_1 = elem.getAttribute('data-card-feature-1');
        if(elem.hasAttribute('data-card-feature-2')) title_feature_2 = elem.getAttribute('data-card-feature-2');
        if(elem.hasAttribute('data-card-feature-3')) title_feature_3 = elem.getAttribute('data-card-feature-3');
        if(elem.hasAttribute('data-card-feature-4')) title_feature_4 = elem.getAttribute('data-card-feature-4');
        if(elem.hasAttribute('data-card-feature-5')) title_feature_5 = elem.getAttribute('data-card-feature-5');

        elem.addEventListener('click', function(e) {
            e.preventDefault();
            if(overlay.classList.contains('overlay-card')) {
                overlay.querySelector('h2').innerHTML = overlay_name;
                overlay.querySelector('.overlay-feature--1').innerHTML = overlay_area + ' м²';
                overlay.querySelector('.overlay-feature--1-title').innerHTML = title_feature_1 + ':';
                overlay.querySelector('.overlay-feature--2').innerHTML = overlay_title;
                overlay.querySelector('.overlay-feature--2-title').innerHTML = title_feature_2 + ':';
                overlay.querySelector('.overlay-feature--3').innerHTML = overlay_floor;
                overlay.querySelector('.overlay-feature--3-title').innerHTML = title_feature_3 + ':';
                overlay.querySelector('.overlay-feature--4').innerHTML = overlay_price;
                overlay.querySelector('.overlay-feature--4-title').innerHTML = title_feature_4 + ':';
                overlay.querySelector('.overlay-feature--5').innerHTML = overlay_price_mes;
                overlay.querySelector('.overlay-feature--5-title').innerHTML = title_feature_5 + ':';
                overlay.querySelector('.overlay-description').innerHTML = overlay_description;

              
                const img = overlay_images.split(', ');
             
                let newDiv = document.createElement("div");
                
                newDiv.classList.add('swiper-wrapper');
                for(let i = 0; i < img.length - 1; i = i + 2) {
                    newDiv.innerHTML += `<div class="swiper-slide">
                        <img src="${img[i]}" alt="${img[i + 1]}">
                    </div>`;       
                }
                let my_div = overlay.querySelector('.overlay-slider');
                my_div.prepend(newDiv);

                new Swiper(".overlay-slider", {
                    modules: [Navigation],
                    navigation: {
                      nextEl: ".overlay-slider .swiper-button-next",
                      prevEl: ".overlay-slider .swiper-button-prev",
                    }
                });
            }
            let show = 1;
            show = find(e.target);
            notshow.forEach(item => {
                if (e.target == item) {
                    show = 0;
                    return;
                }
            });
            if(!show) return;
            containsModal(e, overlay, elem,  btn_delete, overlay_delete, overlay_successful)
        });
    })
}



const showNotice = (e) => {

    const btn = document.querySelectorAll('[data-show-notice]')

    if(btn.length == 0) return;

    const find = (node) => {
        while (node) {

            if (node.hasAttribute('data-notshow-notice')) {

                return 0;
            } else {
                node = node.parentElement;
            }
        }
        return 1;
    }


    btn.forEach(elem => {
        let overlay = document.querySelector("." + elem.getAttribute('data-show-notice'))
        if(!overlay) return;

        const closes = overlay.querySelectorAll('.js--notice-close')

        const notshow = elem.querySelectorAll('[data-notshow-notice]');

        elem.addEventListener('click', function(e) {
            let show = 1;
            show = find(e.target);
            notshow.forEach(item => {

                if (e.target == item) {
                    show = 0;
                    return;
                }
            });

            e.preventDefault();
            if(!show) return;

            overlay.classList.add('show')
        });
        if(closes.length != 0) {
            closes.forEach(close => {
                close.addEventListener('click', function(e) {

                    e.preventDefault()
                    clickClose()
                })
            })
        }

        // overlay.addEventListener('click', function(e) {
        //     if (e.target != overlay) return
        //     clickClose()
        // })

        const clickClose = () => {
            overlay.classList.remove('show')

        }
    })
}

window.showModals = (e, overlayClass = '', text = '') => {
    let overlay = document.querySelector(overlayClass);
    containsModal(e, overlay, null, null, '', text)
}

export {
    showModal,
    showNotice
} ;

