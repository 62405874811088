const sendForm = () => {
    const forms = document.querySelectorAll('.form-send');
    forms.forEach(form => {
        const inputs = form.querySelectorAll('input');
        const textarea = form.querySelectorAll('textarea');

        //** ОТПРАВКА ЗАПРОСА
        const postData = async(url, data) => {
    
            let res = await fetch(url, {
                method: "POST",
                body: data
            });
    
            return await res.text();
        };
    
        //** ОЧИСТКА ФОРМЫ
    
        const clearInputs = () => {
            inputs.forEach(item => {
                item.value = '';
            });
            textarea.forEach(item => {
                item.value = '';
            });
        };
    
        form.querySelector('button[type="submit"]').addEventListener('click', () => {
            inputs.forEach(elem => {
                if (!elem.checkValidity()) elem.parentNode.classList.add('input-error')
                else elem.parentNode.classList.remove('input-error')
            });
            textarea.forEach(elem => {
                if (!elem.checkValidity()) elem.parentNode.classList.add('input-error')
                else elem.parentNode.classList.remove('input-error')
            });
        })
    
        inputs.forEach(elem => {
            elem.addEventListener('change', () => {
                if (!elem.checkValidity()) {
                    elem.parentNode.classList.add('input-error')
                } else {
                    elem.parentNode.classList.remove('input-error')
                }
            })
        });

        textarea.forEach(elem => {
            elem.addEventListener('change', () => {
                if (!elem.checkValidity()) {
                    elem.parentNode.classList.add('input-error')
                } else {
                    elem.parentNode.classList.remove('input-error')
                }
            })
        });
    
        const thanks = document.querySelector('.thanks');
    
        form.addEventListener('submit', (e) => {
            
    
            e.preventDefault();
            const formData = new FormData(form);
            
    
            postData('components/mailto.php', formData)
                .then(res => {
                    console.log('ок');
            })
            .catch(() => console.log('no'))
            .finally(() => {
                clearInputs();
                thanks.classList.add('show');
    
                setTimeout(() => {
                    thanks.classList.remove('show');
                }, 4000);
            
                //console.log('okay')
            });
        });
    })
    
};

const maskPhone = (selector) => {
    try {
        let setCursorPosition = (pos, elem) => {
            elem.focus();

            if (elem.setSelectionRange) {
                elem.setSelectionRange(pos, pos);
            } else if (elem.createTextRange) {
                let range = elem.createTextRange();
                range.collapse(true);
                range.moveEnd('character', pos);
                range.moveStart('character', pos);
                range.select();
            }

        };

        function createMask(event) {
            let matrix = '+7 (___) ___ __ __',
                i = 0,
                def = matrix.replace(/\D/g, ''),
                val = this.value.replace(/\D/g, '');

            if (def.length >= val.length) {
                val = def;
            }

            this.value = matrix.replace(/./g, function(a) {
                return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
            });

            if (event.type === 'blur') {
                if (this.value.length == 2) {
                    this.value = '';
                }
            } else {
                setCursorPosition(this.value.length, this)
            }
        }

        //let inputs = document.querySelectorAll(selector);
        let input = selector;
        //inputs.forEach(input => {
        input.addEventListener('input', createMask);
        input.addEventListener('focus', createMask);
        input.addEventListener('blur', createMask);
        //});
    } catch (e) {}

};

export {
    sendForm,
    maskPhone
}